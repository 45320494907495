import React, { useEffect } from 'react'
import styled from 'styled-components'
import { CloseSquare as CloseIcon } from './Icons'

const Container = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  max-width: 100vw !important;
  position: absolute;
  right: 50%;
  top: ${props => props.offset - 200}px;
  transform: translateX(50%);
  width: 100vw !important;
  z-index: 11;
`

const ClickShield = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: 100vh;
  max-width: 100vw !important;
  position: absolute;
  top: ${props => props.offset}px;
  width: 100vw !important;
  z-index: 10;
  box-shadow: var(--shadow);
`

const Inner = styled.div`
  position: relative;
  background: white;
  padding: 2rem;
  border: 1px solid black;
  border-radius: 5px;
  max-width: 80% !important;
  z-index: 11;
`

const Close = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  fill: var(--black);
  &:hover {
    cursor: pointer;
    fill: ${props => props.theme.ACTIVE_STATE};
  }
`

const Popup = ({ children, onClose }) => {
  useEffect(() => {
    // on mount
    document.body.style.overflow = 'hidden'
  }, [])

  useEffect(
    // on unmount
    () => () => {
      document.body.style.overflow = 'auto'
    },
    []
  )
  const offset = document.documentElement.scrollTop
  return (
    <>
      <ClickShield onClick={onClose} offset={offset} />
      <Container offset={offset}>
        <Inner>
          <Close onClick={onClose}>
            <CloseIcon />
          </Close>
          {children}
        </Inner>
      </Container>
    </>
  )
}

export default Popup
