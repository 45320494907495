import React from 'react'
import styled from 'styled-components'
import { ButtonLabel } from './Label'

const imagePadding = 14

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  & > * {
    margin-right: 1rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
`

const Button = styled.div`
  max-width: 130px;
  width: 100%;
  padding: 1rem 1rem;
  border-radius: 5px;
  border: 1px solid transparent;
  ${({ selected }) =>
    selected &&
    `
    box-shadow: var(--shadow_sm);
    border-color: var(--color-orange);
  `}
  transition: box-shadow 150ms var(--ease-in);
  &:hover {
    cursor: pointer;
    box-shadow: var(--shadow_sm);
    transition: box-shadow 200ms var(--ease-out);
  }
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  ${({ disabled }) =>
    disabled &&
    `
    &:hover {
      cursor: not-allowed;
      box-shadow: none;
    }
  `}
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  margin: 0 auto;
`
const Image = styled.div`
  background: url("${props => props.url}") no-repeat;
  background-size:contain;
  height: ${props => props.size - imagePadding}px;
  width: ${props => props.size - imagePadding}px;
`
const Label = styled(ButtonLabel)`
  font-family: var(--font-sans_serif);
  line-height: 1.125em;
  display: block;
  margin-top: 0.25rem;
  text-align: center;
  text-transform: uppercase;
`
/*
Options should be an array of objects
{
    url: 'path to image to use for option',
    id: 'unique id for option'
}
*/

const RadioImageButton = ({ size = 100, options, onOptionClick, selected }) => (
  <Container>
    {options.map(option => {
      return (
        <Button
          key={option.id}
          disabled={option.disabled}
          onClick={() => !option.disabled && onOptionClick(option.id)}
          selected={option.id === selected}
        >
          <ImageContainer size={size}>
            <Image size={size} url={option.url} />
          </ImageContainer>
          {option.label && <Label>{option.label}</Label>}
        </Button>
      )
    })}
  </Container>
)

export default RadioImageButton
