const black = '#222'
const dark_gray = '#444'
const darker_gray = '#333'
const light_gray = '#999'
const info_gray = '#ddd'
const light_blue = '#F5F7F9'
const orange = 'rgba(250 89 33 / var(--tw-text-opacity))'
const white = 'white'

const defaultTheme = {
  ACTIVE_STATE: orange,
  BACKGROUND: light_blue,
  BUTTON_PRIMARY_BG: black,
  BUTTON_PRIMARY_FG: white,
  CHECKBOX_CHECKED: orange,
  CHECKBOX_UNCHECKED: darker_gray,
  CONTAINER_MAX_WIDTH: '850px',
  CONTAINER_WIDTH: '100%',
  HOVER_STATE: orange,
  INFO_ICON: info_gray,
  INFO_TOOLTIP_BG: light_gray,
  NAVBAR_BG: dark_gray,
  NAVBAR_TEXT: white,
  LABEL_UNDERLINE: orange,
  SLIDER_BG: darker_gray,
  SLIDER_DISABLED: light_gray,
  SLIDER_FG: orange,
  TEXT_COLOR: dark_gray,
}

// to create alternate styles, create a new theme object and export it
// then use that in App.js <ThemeProvider theme={theme}>

export default defaultTheme
