import React, { useState } from 'react'
import { cx } from '@emotion/css'
import WebsiteHeader from './WebsiteHeader'
import NavMobile from './NavMobile'

const Header = ({
  titleCategory = 'Photometry Generator',
  titleProduct = 'Photometry Generator',
  titleBtn = 'Back to Website',
  collapsed = false,
}) => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  return (
    <>
      <NavMobile isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
      <div
        className={cx(
          'fixed top-0 left-0 right-0 overflow-hidden',
          'z-40 shadow-lg mb-6 bg-white'
        )}
      >
        <WebsiteHeader collapsed={collapsed} />
        <section
          className={cx('overflow-hidden px-4', {
            'bg-white': !collapsed,
            'bg-transparent': collapsed,
          })}
        >
          <div
            className={cx(
              'w-full max-w-7xl mx-auto flex items-center justify-between',
              {
                'min-h-[40px]': collapsed,
                'min-h-[60px]': !collapsed,
              }
            )}
          >
            {titleCategory && (
              <h1
                className={cx(
                  'relative -left-[2px] -top-[3px] uppercase text-lg lg:text-2xl text-accent font-[700] whitespace-nowrap tracking-wide '
                )}
                style={{ fontFamily: 'SinkinSansBold' }}
              >
                {titleCategory}
              </h1>
            )}
            <div className="w-full flex items-center justify-end">
              <a
                className={cx(
                  'hidden lg:flex',
                  'uppercase px-12 rounded-full text-xs ring-1 ring-transparent no-underline',
                  'bg-primary hover:bg-white text-white hover:text-primary hover:[box-shadow:_0_0_0_1px_#F4982B]',
                  'transition-all ease-in-out',
                  {
                    'pb-2 pt-[7px]': !collapsed,
                    'py-1 !px-6': collapsed,
                  }
                )}
                href="https://www.energyline.net/systems/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {titleBtn}&ensp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-[16px] w-[16px] relative top-[-1px]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </a>
              <MenuHamburger setIsNavOpen={setIsNavOpen} />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Header

const MenuHamburger = ({ setIsNavOpen }) => {
  return (
    <button
      className={cx(
        'bg-transparent border-0 lg:hidden text-secondary px-2 pt-[6px] pb-[3px] transition-colors',
        'hover:text-accent hover:bg-neutral-50 rounded-full transition-all'
      )}
      onClick={() => setIsNavOpen(true)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </button>
  )
}
