import React, { useContext } from 'react'

import Box from '../UI/Box'
import { Row, Title, Col } from '../UI/Layout'
import Notify from '../UI/Notify'
import RadioButton from '../UI/RadioButton'

import TextControl from './TextControl'
import { FIELD } from '../containers/config'
import { Context as OptionsContext } from '../context/OptionsContext'

const notes = {
  title: 'Important Note:',
  notes: [
    'Photometric files used in AGI and DIALux are point source calculation, this means if you choose a long fixture length you may get unexpected results.',
    'AGI and DIALux have automatic functions and advanced user settings to resolve this issue.',
    'Alternatively, you can download a shorter length and array the fixtures.',
  ],
}

const File = ({ data, fileLengthChanged, fileTypeChanged, profileChanged }) => {
  const { state: options } = useContext(OptionsContext)
  const { fixtureLength, fileType, fixtureWidth } = data
  const hasLengthTips = Number(fixtureLength) > 1200
  return (
    <Box title={'File'}>
      {/* Section 1 */}
      <Row gap>
        <Col width="60%" gap>
          <Title>Energyline fixture width (mm)</Title>
          <RadioButton
            options={options.profileWidths}
            onOptionClick={id => profileChanged(id)}
            selected={fixtureWidth}
          />
        </Col>
        <Col width="40%">
          <Title>Photometric file length (mm)</Title>
          <TextControl
            errorKey={FIELD.fixtureLength}
            onChange={length => fileLengthChanged(length)}
            value={fixtureLength}
          />
          <Notify {...notes} isActive={hasLengthTips} />
        </Col>
      </Row>
      {/* Section 2 */}
      <Row>
        <Col>
          <Title>File Type</Title>
          <RadioButton
            options={options.fileTypes}
            onOptionClick={id => fileTypeChanged(id)}
            selected={fileType}
          />
        </Col>
      </Row>
    </Box>
  )
}

export default File
