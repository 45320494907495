import React from 'react'
import styled from 'styled-components'

const BoxShadow = styled.div`
  background: white;
  border: 1px solid #dedede;
  border-radius: 5px;
  position: relative;
  padding: ${({ collapsed }) => (collapsed ? '0' : '0 var(--box-space)')};
  width: 100%;
  margin-bottom: 1rem;
  transition: box-shadow 200ms var(--ease-in);
  &:hover {
    box-shadow: var(--shadow_sm);
    transition: box-shadow 100ms var(--ease-out);
  }
`

const BoxHeader = styled.div`
  background: ${({ collapsed }) => (collapsed ? '#ccc' : 'transparent')}
  border-bottom: 1px solid var(--color-info_gray);
  padding: ${({ collapsed }) =>
    collapsed ? '.5rem var(--box-space)' : 'var(--box-space) 0'};
`

const BoxContent = styled.div`
  padding: var(--box-space) 0;
`

const BoxTitle = styled.div`
  font-family: var(--font-sans_serif__bold);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: ${({ collapsed }) => (collapsed ? 'regular' : 'bold')};
  color: ${({ collapsed }) => (collapsed ? 'white' : 'inhere')};
`

const Box = ({ children, collapsed = false, info = null, title }) => {
  // if in collapsed state just show the title, if set
  if (collapsed) {
    return (
      <BoxShadow collapsed>
        {title && (
          <BoxHeader collapsed>
            <BoxTitle collapsed>{title}</BoxTitle>
          </BoxHeader>
        )}
      </BoxShadow>
    )
  }

  return (
    <BoxShadow>
      {title && (
        <BoxHeader>
          <BoxTitle>{title}</BoxTitle>
        </BoxHeader>
      )}
      <BoxContent>{children}</BoxContent>
    </BoxShadow>
  )
}

export { BoxShadow }
export default Box
