import React, { useContext } from 'react'
import styled from 'styled-components'

import { isTopMostError } from '../containers/logic'
import ErrorContext from '../containers/ErrorContext'

import TextBox from '../UI/TextBox'
import FieldError from '../UI/FieldError'
import { Row, Col } from '../UI/Layout'

import ScrollToError from './ScrollToError'

const Container = styled.div`
  margin-top: ${props => props.marginTop || '0'};
  opacity: ${props => (props.disabled ? 0.35 : 1)};
  cursor: pointer;
  width: 100%;
`

const Label = styled.div`
  display: flex;
  align-item: center;
  font-size: 0.75rem;
`

const TextControl = ({
  disabled = false,
  errorKey,
  placeholder = '',
  onChange,
  onEnable,
  type = 'number',
  value,
  label,
}) => {
  const errors = useContext(ErrorContext)
  const enable = () => disabled && onEnable()

  // Display error if there is one,
  // Make it a scroll to error if this error is highest up the page
  let error = null
  if (errors[errorKey]) {
    const scrollToError = isTopMostError(errorKey, errors)
    error = scrollToError ? (
      <ScrollToError>{errors[errorKey]}</ScrollToError>
    ) : (
      <FieldError>{errors[errorKey]}</FieldError>
    )
  }

  return (
    <Container disabled={disabled} onClick={enable}>
      {label ? (
        <Row>
          <Col width="40%" verticalCenter>
            <Label>{label}</Label>
          </Col>
          <Col width="60%">
            <TextBox
              error={error}
              onChange={onChange}
              onSelect={enable}
              value={value}
              type={type}
              disabled={disabled}
              placeholder={placeholder}
            />
          </Col>
        </Row>
      ) : (
        <TextBox
          error={error}
          onChange={onChange}
          onSelect={enable}
          value={value}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
    </Container>
  )
}

export default TextControl
