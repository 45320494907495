import React from 'react'

import Box from '../UI/Box'
import { Row, Col, Title } from '../UI/Layout'
import CheckBox from '../UI/Checkbox'

import { FIELD } from '../containers/config'

import TextControl from './TextControl'

const Details = ({ data, emailChanged, rememberMe, rememberMeChanged }) => {
  const { email } = data
  return (
    <Box title={'Details'}>
      <Row>
        <Col width="50%">
          <Title>Email Address (required)</Title>
          <TextControl
            errorKey={FIELD.email}
            onChange={emailChanged}
            value={email}
            type="email"
          />
          <CheckBox
            checked={rememberMe}
            onChange={() => rememberMeChanged(!rememberMe)}
            label="Remember Me"
          />
        </Col>
      </Row>
    </Box>
  )
}

export default Details
