import React from 'react'
import styled from 'styled-components'
import defaultTheme from '../styles/theme'

const { CONTAINER_MAX_WIDTH } = defaultTheme

const Container = styled.div`
  background-color: var(--color-info_gray);
  padding: 1rem 0;
`

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  width: 80%;
  & > li {
    font-family: var(--font-sans_serif);
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  @media (min-width: ${CONTAINER_MAX_WIDTH}) {
    width: 50%;
    max-width: var(--breakpoint);
  }
`

const Link = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const Footer = () => (
  <Container>
    <Ul>
      <li>Energyline Ltd</li>
      <li>|</li>
      <li>
        <Link href="https://www.energyline.net/privacy-policy" target="_blank">
          Privacy Policy
        </Link>
      </li>
      <li>|</li>
      <li>All rights reserved</li>
    </Ul>
  </Container>
)

export default Footer
