import React from 'react'
import styled, { keyframes } from 'styled-components'

// uses CSS from https://tobiasahlin.com/spinkit/

const bounce = keyframes`
0%,
100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
}

50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
}
`

const Wrapper = styled.div`
  height: 40px;
  margin: 10px auto;
  position: relative;
  width: 40px;
`

const BounceOne = styled.div`
  animation: ${bounce} 2s infinite ease-in-out;
  border-radius: 50%;
  background-color: rgb(245, 163, 63);
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
`

const BounceTwo = styled(BounceOne)`
  animation-delay: -1s;
`
const Spinner = () => (
  <Wrapper>
    <BounceOne />
    <BounceTwo />
  </Wrapper>
)

export default Spinner
