import React from 'react'
import styled from 'styled-components'
import { ButtonLabel } from './Label'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  & > * {
    margin-right: var(--gap);
    margin-bottom: var(--box-space);
  }
  & > *:last-child {
    margin-right: 0;
  }
`

const Option = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-info_gray);
  border-radius: 5px;
  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.ACTIVE_STATE};
    border-color: ${theme.HOVER_STATE};
    & > label { color: #fff ; }
  `}
  height: ${props => props.size}px
  justify-content: center;
  opacity: ${({ disabled, greyOut }) => (disabled || greyOut ? 0.4 : 1)};  
  width: ${props => props.size}px;
  transition: box-shadow 150ms var(--ease-in);
  &:hover {
    cursor: pointer;
    border: 1px solid var(--color-orange);
    box-shadow: var(--shadow_sm);
    transition: box-shadow 200ms var(--ease-out);
  }
  ${({ disabled }) =>
    disabled &&
    `
    &:hover {
      cursor: not-allowed;
      border: 1px solid var(--color-info_gray);
      box-shadow: none;
    }
  `}
`

const BtnLabel = styled.label`
  color: #000;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
`

const Label = styled(ButtonLabel)`
  font-family: 'SinkinSans', sans-serif;
  font-weight: 700;
  line-height: 1.3em;
  margin-top: 0.5em;
  text-align: center;
  width: ${({ size }) => size + 'px'};
  text-transform: uppercase;
`

const RadioButton = ({
  size = 75,
  width = 100,
  options = [],
  onOptionClick,
  selected,
  disabled = false,
  onEnable = () => {},
}) => {
  const enable = () => disabled && onEnable()
  const handleClick = option => {
    if (option.disabled) return
    return onOptionClick(option.id)
  }

  return (
    <Container width={width} onClick={enable}>
      {options.map(option => {
        return (
          <div key={option.id}>
            <Option
              key={option.id}
              disabled={option.disabled}
              selected={disabled ? false : option.id === selected}
              size={size}
              onClick={() => handleClick(option)}
              greyOut={disabled}
            >
              <BtnLabel>{option.btnLabel}</BtnLabel>
            </Option>
            {option.label && <Label size={size}>{option.label}</Label>}
          </div>
        )
      })}
    </Container>
  )
}

export default RadioButton
