import React from 'react'
import styled from 'styled-components'

const BaseButton = styled.button.attrs(props => ({
  background: props.background || 'white',
  color: props.color || '#222',
  fontSize: props.fontSize || '8px',
  padding: props.padding || '1rem',
  width: props.width || 'auto',
}))`
  background: ${props => props.background};
  border: 0 solid #dedede;
  border-radius: 4px;
  color: ${props => props.color};
  display: flex;
  flex: 1;
  font-family: 'SinkinSansRegular';
  font-size: ${props => props.fontSize};
  justify-content: center;
  padding: ${props => props.padding};
  width: ${props => props.width};
  max-width: ${props => props.width};
  opacity: ${props => (props.disabled ? 0.5 : 1)}
  transition: box-shadow 150ms var(--ease-in);

  &:hover {
    opacity: ${props => (props.disabled ? 0.5 : 1)}
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    box-shadow: var(--shadow);
    transition: box-shadow 200ms var(--ease-out);
  }
`

const PrimaryButton = props => (
  <Button background={'black'} color={'white'} fontSize={'8px'} {...props} />
)

const LargeBlueButton = props => (
  <Button
    background={'rgb(245, 155, 44)'}
    color={'white'}
    fontSize={'1.25em'}
    padding={'1.15em'}
    {...props}
  />
)

const Button = ({
  background,
  color,
  children,
  disabled,
  fontSize,
  label,
  onClick,
  padding,
  width,
}) => {
  const handleClick = event => {
    if (disabled) {
      return
    }
    onClick(event)
  }

  return (
    <BaseButton
      background={background}
      color={color}
      disabled={disabled}
      fontSize={fontSize}
      padding={padding}
      width={width}
      type={'button'}
      onClick={handleClick}
    >
      {label || children}
    </BaseButton>
  )
}

export default Button

export { BaseButton, LargeBlueButton, PrimaryButton }
