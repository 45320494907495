import React from 'react'
import { cx } from '@emotion/css'

import { routes } from './WebsiteHeader'

const NavMobile = ({ isNavOpen, setIsNavOpen }) => {
  return (
    // Overlay
    <div
      className={cx(
        'h-full w-full fixed z-50 left-0 top-0',
        'bg-black bg-opacity-90 overflow-hidden transition-all duration-500',
        { 'h-0': isNavOpen === false }
      )}
    >
      {/* Menu contents */}
      <div className="relative top-[12%] bottom-[9%] flex flex-col w-full items-center justify-between text-white">
        <CloseButton setIsNavOpen={setIsNavOpen} />
        <div className="">
          {routes.map(({ pathname, label }) => (
            <a
              key={pathname}
              href={pathname}
              className={cx(
                'leading-[8vh] text-[3vh] text-white font-normal',
                'hover:text-[#F59B2C] text-center uppercase block transition-all no-underline tracking-wide'
              )}
              onClick={() => {
                setIsNavOpen(false)
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {label}
            </a>
          ))}
        </div>
        <HomeButton setIsNavOpen={setIsNavOpen} />
      </div>
    </div>
  )
}

export default NavMobile

const CloseButton = ({ setIsNavOpen }) => (
  <button
    className={cx(
      'mb-[8vh]',
      'border-none shadow-[0_0_0px_2px_rgb(255,255,255)] pt-[6px] pb-[3px] rounded-full transition-all',
      'hover:bg-white hover:shadow-none hover:text-[#F59B2C]',
      'active:bg-[#F59B2C] active:text-white'
    )}
    onClick={() => setIsNavOpen(false)}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 "
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
)

const HomeButton = ({ setIsNavOpen }) => (
  <a
    className={cx(
      'mt-[8vh] flex items-center',
      'text-sm shadow-[0_0_0px_2px_rgb(255,255,255)] py-2 pb-[10px] px-6 rounded-full transition-all no-underline',
      'text-white flex space-x-1',
      'hover:bg-white hover:shadow-none hover:text-[#F59B2C]',
      'active:bg-[#F59B2C] active:text-white'
    )}
    onClick={() => setIsNavOpen(false)}
    href="https://www.energyline.net/systems/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <p className="uppercase">Back to website</p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-[18px] w-[18px] relative"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
      />
    </svg>
  </a>
)
