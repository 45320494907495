import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const size = 7

const borderColor =
  'transparent transparent var(--color-black, #222222) var(--color-black, #222222)'

const Card = styled.div`
  position: relative;
`
const Box = styled.div`
  position: absolute;
  visibility: hidden;
  top: ${size + 5}px;
  left: 0px;
  transform: translate(0, 50%);
  width: 100%;
  padding: 1rem 1.5rem;
  color: transparent;
  border-radius: 5px;
  transition: all 0.2s var(--ease-in);
  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 1rem;
    top: -${size}px;
    position: absolute;
    border: ${size}px solid transparent;
    transform: rotate(135deg);
    border-color: ${borderColor};
  }
  color: #ffffff;
  background-color: var(--color-black, #222222);
  box-shadow: var(--shadow);
  ${({ active }) =>
    active &&
    `
      visibility: visible;
      transition: all 0.2s var(--ease-out);
      color: #FFFFFF;
      background-color: var(--color-black, #222222);
      transform: translate(0, 0);
      &:before {
        border-color: ${borderColor};
      }
  `}
`

const P = styled.p`
  font-size: 0.65rem;
  font-family: var(--font-sans_serif__lighter, sans-serif);
  margin-bottom: 0.5rem;
  font-weight: lighter;
`

const Title = styled.div`
  font-family: var(--font-sans_serif__bold, sans-serif);
  font-size: 0.65rem;
  margin-bottom: 0.75rem;
`
const Bar = styled.div`
  display: flex;
  justify-content: flex-end;
`

const BtnClose = ({ onClick = () => {} }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={onClick}>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3345 2.00018H7.66549C4.26791 2.00018 2.00049 4.43278 2.00049 7.91618V16.0842C2.00049 19.5709 4.26186 22.0002 7.66549 22.0002H16.3335C19.7381 22.0002 22.0005 19.5709 22.0005 16.0842V7.91618C22.0005 4.42969 19.7383 2.00018 16.3345 2.00018ZM7.66549 3.50018H16.3345C18.885 3.50018 20.5005 5.23515 20.5005 7.91618V16.0842C20.5005 18.7653 18.8849 20.5002 16.3335 20.5002H7.66549C5.11525 20.5002 3.50049 18.7655 3.50049 16.0842V7.91618C3.50049 5.23856 5.12083 3.50018 7.66549 3.50018ZM9.07071 9.0624C9.33701 8.79616 9.75367 8.772 10.0473 8.98988L10.1314 9.06251L11.9991 10.9306L13.8652 9.06455C14.1581 8.77166 14.6329 8.77166 14.9258 9.06455C15.1921 9.33082 15.2163 9.74748 14.9984 10.0411L14.9258 10.1252L13.0596 11.9914L14.9274 13.8595C15.2202 14.1524 15.2202 14.6273 14.9273 14.9202C14.661 15.1864 14.2443 15.2106 13.9507 14.9927L13.8666 14.9201L11.999 13.052L10.1338 14.9172C9.84093 15.2101 9.36605 15.2101 9.07316 14.9172C8.80689 14.6509 8.78269 14.2343 9.00054 13.9407L9.07316 13.8566L10.9384 11.9913L9.0706 10.1231C8.77774 9.83013 8.77779 9.35526 9.07071 9.0624Z"
        fill="#FFF"
      />
    </g>
  </svg>
)

const Notify = ({
  title = '',
  notes = ['note 1'],
  isActive = false,
  isControlled = false,
}) => {
  const [active, setActive] = useState(isActive)

  useEffect(() => {
    setActive(isActive)
  }, [isActive])

  return (
    <Card>
      <Box active={active}>
        {title && <Title>{title}</Title>}
        {notes &&
          notes.length &&
          notes.map((note, index) => <P key={`note_${index}`}>{note}</P>)}
        {isControlled && (
          <Bar>
            <BtnClose onClick={() => setActive(!active)} />
          </Bar>
        )}
      </Box>
    </Card>
  )
}

export default Notify
