import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  background: ${props => (props.error ? 'mistyrose' : 'white')};
  border: 1px solid ${props => (props.error ? 'red' : '#dedede')};
  border-radius: 3px;
  width: 100%;
  padding: 0.75rem;
`
const TextBox = ({
  error,
  onChange,
  onSelect,
  value,
  type = 'text',
  placeholder = '',
  required = false,
  disabled = false,
}) => (
  <>
    <Input
      type={type}
      value={value}
      onChange={event => onChange(event.target.value)}
      onSelect={onSelect}
      error={error}
      placeholder={placeholder}
      required={required}

      // disabled={disabled}
    />
    {error}
  </>
)

export default TextBox
