/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'

import Box from '../UI/Box'
import RadioImageButton from '../UI/RadioImageButton'
import { Row, Title, Col } from '../UI/Layout'
import RadioButton from '../UI/RadioButton'

import TextControl from './TextControl'
import { Context as OptionsContext } from '../context/OptionsContext'

import { FIELD, INPUT_MODE, DISTRIBUTION } from '../containers/config'

const DownlightSpecification = ({
  data,
  downlightTypeChanged,
  downlightChanged,
  downlightLumensChanged,
  inputModeChanged,
}) => {
  const {
    state: options,
    updateProfileWidths,
    updateDistributionType,
  } = useContext(OptionsContext)
  const [mode, setMode] = useState(INPUT_MODE.RADIO)
  useEffect(() => {
    inputModeChanged(mode)
  }, [mode])

  useEffect(() => {
    updateProfileWidths({
      distribution: data.distribution,
      downDistribution: data.downDistribution,
      upDistribution: data.upDistribution,
    })

    updateDistributionType({
      profileWidths: data.profileWidths,
    })
  }, [data])

  return (
    <Box
      title={'Direct Lighting Distribution'}
      collapsed={data.distribution === DISTRIBUTION.UP}
    >
      {/* Section 1 */}
      <Row gap>
        <Col>
          <Title>Distribution</Title>
          <RadioImageButton
            options={options.downlightTypes}
            onOptionClick={downlightTypeChanged}
            selected={data.downDistribution}
          />
        </Col>
      </Row>
      {/* Section 2 */}
      <Row>
        <Col maxWidth="var(--radio-group-max_width)">
          <Title>Light output (Lumens per metre)</Title>
          <RadioButton
            options={options.lumensOutputs}
            disabled={mode === INPUT_MODE.TEXT}
            onEnable={() => setMode(INPUT_MODE.RADIO)}
            onOptionClick={downlightChanged}
            selected={data.downlight}
          />
          <TextControl
            disabled={mode === INPUT_MODE.RADIO}
            onEnable={() => setMode(INPUT_MODE.TEXT)}
            errorKey={FIELD.downLumens}
            onChange={downlightLumensChanged}
            value={data.downLumens}
            label="Or enter custom lumens/m"
          />
        </Col>
      </Row>
    </Box>
  )
}

export default DownlightSpecification
