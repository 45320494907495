import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import FieldError from '../UI/FieldError'

// When this component is rendered the page will scroll to it
// I.e. only render this component when you want to display it, not before
const ScrollToError = ({ children }) => {
  const errorEl = useRef()
  useEffect(() => {
    const dom = ReactDOM.findDOMNode(errorEl.current)
    const elementRect = dom.getBoundingClientRect()
    const absoluteElementTop = elementRect.top + window.pageYOffset
    const middle = absoluteElementTop - window.innerHeight / 2
    window.scrollTo({ left: 0, top: middle, behavior: 'smooth' })
  }, [errorEl])

  return <FieldError ref={errorEl}>{children}</FieldError>
}

export default ScrollToError
