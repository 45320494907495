import React from 'react'
import styled from 'styled-components'
import { LargeBlueButton } from '../UI/Button'
import { SVGQuestion } from '../UI/Icons'

import popupService from '../services/popup.service'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 2rem;
`

const Icon = styled.div`
  margin-top: -3px;
  margin-left: 3px;
  width: 15px;
  height: 15px;
  fill: var(--color-light_gray);
`

const DistributionType = ({ disabled, onDownload, text }) => {
  return (
    <Wrapper>
      <div style={{ width: '50%', display: 'flex' }}>
        <LargeBlueButton disabled={disabled} onClick={onDownload} width="100%">
          {text}
        </LargeBlueButton>

        <Icon
          onClick={popupService.showHelpPopUp}
          data-tip="Learn more about Energyline photometry file naming"
        >
          <SVGQuestion></SVGQuestion>
        </Icon>
      </div>
    </Wrapper>
  )
}

export default DistributionType
