import styled from 'styled-components'

export default styled.span`
  font-family: 'SinkinSansLight', sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  color: red;
  padding-top: 0.25em;
  margin-top: 0.25em;
  margin-left: 0.15em;
`
