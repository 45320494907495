/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'

import Box from '../UI/Box'
import RadioImageButton from '../UI/RadioImageButton'
import { Row, Col } from '../UI/Layout'
import { Context as OptionsContext } from '../context/OptionsContext'

const DistributionType = ({ data, onChange }) => {
  const { state: options, updateProfileWidths } = useContext(OptionsContext)

  useEffect(() => {
    // Update business logic
    updateProfileWidths({
      distribution: data.distribution,
      downDistribution: data.downDistribution,
      upDistribution: data.upDistribution,
    })
  }, [data])

  return (
    <Box title={'Distribution Type'}>
      <Row>
        <Col>
          <RadioImageButton
            options={options.distributionTypes}
            onOptionClick={onChange}
            selected={data.distribution}
          />
        </Col>
      </Row>
    </Box>
  )
}

export default DistributionType
