import {
  defaultState,
  emailRegex,
  FIELD,
  INPUT_MODE,
  DISTRIBUTION,
  LIGHT_TYPE,
} from './config'

export const getChanges = (state, field, value) => {
  // update the field that actually changed
  let updates = {
    [field]: value,
  }

  // Update any other fields the need to change
  switch (field) {
    case FIELD.distribution:
      // Whenever the distribution changes we RESET the form
      // But we still need to set enable55 appropriately for the current value
      updates = {
        ...defaultState,
        [field]: value,
        ...(value === DISTRIBUTION.UP && {
          upLumens: 2000,
          uplight: 2000,
        }),
        email: state.email,
      }
      break
    case FIELD.downDistribution:
      if (
        state.fixtureWidth === 55 &&
        !shouldEnable55(state.distribution, value, state.upDistribution)
      ) {
        updates.fixtureWidth = 75
      }
      if (
        state.fixtureWidth === 65 &&
        !shouldEnable65(state.distribution, value, state.upDistribution)
      ) {
        updates.fixtureWidth = 75
      }
      break
    case FIELD.downlight:
      // UI: Sync downLumens with downlight
      updates[FIELD.downLumens] = value
      // Business Logic: upLumens reacts on downLumens
      if (state.upInputMode === INPUT_MODE.RADIO)
        updates[FIELD.upLumens] = Math.round(value * state.uplight)
      break
    case FIELD.downLumens:
      // Business Logic: upLumens reacts on downLumens
      if (state.upInputMode === INPUT_MODE.RADIO)
        updates[FIELD.upLumens] = Math.round(value * state.uplight)
      break
    case FIELD.downInputMode:
      if (value === INPUT_MODE.RADIO) {
        updates[FIELD.downLumens] = state[FIELD.downLumens]
        updates.downLumens = updates.downlight || state.downlight
        if (state.upInputMode === INPUT_MODE.RADIO) {
          updates.upLumens = Math.round(updates.downLumens * state.uplight)
        }
      }
      break
    case FIELD.upDistribution:
      if (
        state.fixtureWidth === 55 &&
        !shouldEnable55(state.distribution, value, state.upDistribution)
      ) {
        updates.fixtureWidth = 75
      }
      if (
        state.fixtureWidth === 65 &&
        !shouldEnable65(state.distribution, value, state.upDistribution)
      ) {
        updates.fixtureWidth = 75
      }
      if (
        state.fixtureWidth === 108 &&
        !shouldEnable108(state.distribution, value)
      ) {
        updates.fixtureWidth = 75
      }
      break
    case FIELD.uplight:
      if (state.distribution === DISTRIBUTION.UP) {
        updates.upLumens = value
      } else {
        updates.upLumens = Math.round(state.downLumens * value)
      }
      break
    case FIELD.upLumens:
      updates.upLumens = Number(updates.upLumens)
      break
    case FIELD.upInputMode:
      if (value === INPUT_MODE.RADIO) {
        if (state.distribution === DISTRIBUTION.UP) {
          // Reset uplight to default state
          if (updates.uplight < 1) {
            updates.uplight = 2000
            updates.upLumens = 2000
          } else {
            updates.upLumens = state.uplight
          }
        } else {
          updates.upLumens = state.downLumens * state.uplight
        }
      }
      break
    default:
      break
  }

  return {
    ...state,
    ...updates,
  }
}

export const getPostData = state => {
  // Covert State to API Body
  const data = {
    source: process.env.REACT_APP_NAME,
    distribution: state.distribution,
    downDistribution: state.downDistribution,
    downLumens: state.downLumens,
    email: state.email,
    fileType: state.fileType,
    fixtureLength: state.fixtureLength,
    fixtureWidth: state.fixtureWidth,
    upDistribution: state.upDistribution,
    upLumens: state.upLumens,
  }

  // Remove unnecessary properties in POST request
  if (data.distribution === 'down') {
    delete data.upDistribution
    delete data.upLumens
  } else if (data.distribution === 'up') {
    delete data.downDistribution
    delete data.downLumens
  }

  return data // just the stuff we want to post
}

// Determine if the supplied error key is for the topmost (visible) error on the page
export const isTopMostError = (error, errors) => {
  // list of all possible errors on the page, listed in order of display on the page
  // E.g. downLumens is displayed at top of page, email at the bottom
  const errorOrder = [
    FIELD.downLumens,
    FIELD.upLumens,
    FIELD.fixtureLength,
    FIELD.email,
  ]
  const getErrorPosition = error => errorOrder.findIndex(e => e === error)
  //const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const reducer = (position, error) => {
    const errorPosition = getErrorPosition(error)
    return errorPosition < position ? errorPosition : position
  }
  const errorKeys = Object.keys(errors)
  const lowestPositionError = errorKeys.reduce(reducer, errorOrder.length)
  const thisErrorPosition = getErrorPosition(error)
  return thisErrorPosition === lowestPositionError
}

export const validateField = (state, errors, field, submitted = false) => {
  if (
    !submitted &&
    [
      FIELD.downLumens,
      FIELD.upLumens,
      FIELD.fixtureLength,
      FIELD.email,
    ].includes(field)
  ) {
    // we don't validate these fields until the user submits
    // as it happens, this is currently now ALL fields, but leave as is,
    // so can have other fields validate immediately
    return errors
  }
  const getFieldValue = field => state[field]

  let lumens

  switch (field) {
    case FIELD.downLumens:
      if (
        state.distribution === DISTRIBUTION.DOWN ||
        state.distribution === DISTRIBUTION.UP_DOWN
      ) {
        lumens = getFieldValue(field)

        if (!lumens) {
          delete errors[field]
        } else if (lumens < 900 || lumens > 4000) {
          errors[field] = 'Value must be between 900 and 4000'
        } else {
          delete errors[field]
        }
      }

      break
    case FIELD.upLumens:
      if (
        state.distribution === DISTRIBUTION.UP ||
        state.distribution === DISTRIBUTION.UP_DOWN
      ) {
        lumens = getFieldValue(field)
        if (!lumens) {
          delete errors[field]
        } else if (lumens < 300 || lumens > 4000) {
          errors[field] = 'Value must be between 300 and 4000'
        } else {
          delete errors[field]
        }
      }

      break

    case FIELD.email:
      let email = getFieldValue(field)
      if (!email) {
        errors[field] = 'This field is required'
      } else {
        const match = email.match(emailRegex)
        if (match) {
          delete errors[field]
        } else {
          errors[field] = 'Please enter a valid email'
        }
      }
      break

    case FIELD.fixtureLength:
      let fixtureLength = getFieldValue(field)
      if (!fixtureLength) {
        errors[field] = 'This field is required'
      } else if (parseInt(fixtureLength) === 0) {
        errors[field] = 'Fixture Length must be greater than 0'
      } else {
        delete errors[field]
      }
      break

    default:
      break
  }

  return errors
}

/**
 * test all specified fields for errors
 * these are the fields we know might have errors
 * this guards against email being empty to start with for example
 */
export const validateForm = (state, submitted = false) => {
  const initialErrors = {
    ...state.errors,
  }

  const reducer = (errors, field) =>
    validateField(state, errors, field, submitted)

  // Note: that some fields will not be validated if submitted is FALSE,
  // see validateField()
  return [
    FIELD.downLumens,
    FIELD.upLumens,
    FIELD.email,
    FIELD.fixtureLength,
  ].reduce(reducer, initialErrors)
}

export const disableLouvre = (UpOrDownTypes, disabled) => {
  const computed = UpOrDownTypes.slice()
  const louvre = computed.find(item => item.id === LIGHT_TYPE.LOUVRE)
  if (louvre) louvre.disabled = disabled
  return computed
}

export const shouldDisableLouvre = profileWidth => {
  let shouldDisabled = false
  if ([55, 65].includes(profileWidth)) shouldDisabled = true

  return shouldDisabled
}

export const enable55 = (profileWidths, enable) => {
  const updatedProfileWidths = profileWidths.slice()
  const profile55 = updatedProfileWidths.find(w => w.id === 55)
  profile55.disabled = !enable
  return updatedProfileWidths
}

export const shouldEnable55 = (
  distribution,
  downDistribution,
  upDistribution
) => {
  let shouldEnable = false

  if (distribution === DISTRIBUTION.DOWN) {
    shouldEnable = downDistribution === LIGHT_TYPE.SATIN
  }
  if (distribution === DISTRIBUTION.UP) {
    shouldEnable = upDistribution === LIGHT_TYPE.SATIN
  }
  if (distribution === DISTRIBUTION.UP_DOWN) {
    shouldEnable =
      downDistribution === LIGHT_TYPE.SATIN &&
      upDistribution === LIGHT_TYPE.SATIN
  }

  return shouldEnable
}

export const disableMicroprism = (DownTypes, disabled) => {
  const computed = DownTypes.slice()
  const microprism = computed.find(item => item.id === LIGHT_TYPE.MICROPRISM)
  if (microprism) microprism.disabled = disabled
  return computed
}

export const shouldDisableMicroprism = profileWidth => {
  let shouldDisabled = false
  if ([55, 108].includes(profileWidth)) shouldDisabled = true

  return shouldDisabled
}

export const disableCeilingWash = (UpTypes, disabled) => {
  const computed = UpTypes.slice()
  const ceilingWash = computed.find(item => item.id === LIGHT_TYPE.CEILING_WASH)
  if (ceilingWash) ceilingWash.disabled = disabled
  return computed
}

export const shouldDisableCeilingWash = profileWidth => {
  let shouldDisabled = false
  if ([55].includes(profileWidth)) shouldDisabled = true

  return shouldDisabled
}

export const enable65 = (profileWidths, enable) => {
  const updatedProfileWidths = profileWidths.slice()
  const profile65 = updatedProfileWidths.find(w => w.id === 65)
  profile65.disabled = !enable
  return updatedProfileWidths
}

export const shouldEnable65 = (
  distribution,
  downDistribution,
  upDistribution
) => {
  let shouldEnable = false

  if (distribution === DISTRIBUTION.DOWN) {
    shouldEnable = downDistribution !== LIGHT_TYPE.LOUVRE
  }
  if (distribution === DISTRIBUTION.UP) {
    shouldEnable = upDistribution === LIGHT_TYPE.CEILING_WASH
  }
  if (distribution === DISTRIBUTION.UP_DOWN) {
    shouldEnable =
      downDistribution !== LIGHT_TYPE.LOUVRE &&
      upDistribution === LIGHT_TYPE.CEILING_WASH
  }

  return shouldEnable
}

export const disableSatinUp = (UpTypes, disabled) => {
  const computed = UpTypes.slice()
  const satinUp = computed.find(item => item.id === LIGHT_TYPE.SATIN)
  if (satinUp) satinUp.disabled = disabled
  return computed
}

export const shouldDisableSatinUp = profileWidth => {
  let shouldDisabled = false
  if ([65].includes(profileWidth)) shouldDisabled = true

  return shouldDisabled
}

export const enable108 = (profileWidths, enable) => {
  const updatedProfileWidths = profileWidths.slice()
  const profile108 = updatedProfileWidths.find(w => w.id === 108)
  profile108.disabled = !enable
  return updatedProfileWidths
}

export const shouldEnable108 = (distribution, downDistribution) => {
  let shouldEnable = false

  if (distribution === DISTRIBUTION.DOWN) {
    shouldEnable = downDistribution !== LIGHT_TYPE.MICROPRISM
  }
  if (distribution === DISTRIBUTION.UP) {
    shouldEnable = true
  }
  if (distribution === DISTRIBUTION.UP_DOWN) {
    shouldEnable = downDistribution !== LIGHT_TYPE.MICROPRISM
  }

  return shouldEnable
}

/**
 * A errorHandler function
 * Handle Axios request error
 * Handle Javascript Error instance
 * Handle Javascript TypeError
 * Handle Any error and return default error message
 * @param {Object || Any} error
 * @returns {String}
 */
export const errorHandler = (
  error,
  message = 'An error occurred, please try again later'
) => {
  if (!error) return message
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.info(error.response.data)
    console.info(error.response.status)
    console.info(error.response.headers)
    if (error.response.data.error) {
      if (typeof error.response.data.error === 'string') {
        message = error.response.data.error
      }
    }
    return message
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.info(error.request)
    return message
  }
  // Something happened in setting up the request that triggered an Error
  if (error.config) console.info(error.config)
  if (error.message) {
    console.info(error.message)
    if (typeof error.message === 'string') {
      message = error.message
    }
  }
  return message
}
