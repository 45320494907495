import { Subject } from 'rxjs'

import { ErrorContent, HelpContent } from '../UI/PopUpContent'

const subject = new Subject()

const showPopUp = content => subject.next(content)

export default {
  showHelpPopUp: () => showPopUp(HelpContent),
  showErrorPopUp: () => showPopUp(ErrorContent),
  showPopUp: content => showPopUp(content),
  closePopUp: () => showPopUp(null),
  popup$: subject.asObservable(),
}
