import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  margin: 1rem 0 0;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${props =>
    props.checked ? 'var(--color-orange)' : 'papayawhip'};
  border-radius: var(--border-radius_sm);
  transition: all 200ms var(--ease-out);
  border: 1px solid var(--color-orange);

  ${Icon} {
    display: block;
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`
const LabelText = styled.span`
  font-family: var(--font-sans_serif__bold);
  font-size: 0.75rem;
  line-height: 1;
  margin-left: 0.5rem;
  text-transform: uppercase;
`

const Checkbox = ({ className, checked, label, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
    <LabelText>{label}</LabelText>
  </CheckboxContainer>
)

export default Checkbox
