import React, { useEffect } from 'react'
import styled from 'styled-components'

import Spinner from './Spinner'

const Wrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  display: flex;
  height: 100vh !important;
  justify-content: center;
  max-width: 100vh !important;
  position: absolute;
  top: 63%;
  width: 100vw !important;
  z-index: 1000;
`

const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Message = styled.span`
  font-size: 1em;
  color: #fff;
`

const Loader = ({ msg = '' }) => {
  useEffect(() => {
    // on mount
    document.body.style.overflow = 'hidden'
  }, [])

  useEffect(
    // on unmount
    () => () => {
      document.body.style.overflow = 'auto'
    },
    []
  )

  const offset = document.documentElement.scrollTop
  return (
    <Wrapper offset={offset}>
      <Inner>
        <Spinner />
        {msg ? <Message>{msg}</Message> : null}
      </Inner>
    </Wrapper>
  )
}

export default Loader
