/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { hotjar } from 'react-hotjar'

import Details from '../components/Details'
import DistributionType from '../components/DistributionType'
import Download from '../components/Download'
import DownlightSpecification from '../components/DownlightSpecification'
import File from '../components/File'
import UplightSpecification from '../components/UplightSpecification'

import PopUp from '../UI/Popup'
import Loader from '../UI/Loader'
import Debug from '../UI/Debug'

import ErrorContext from './ErrorContext'
import popupService from '../services/popup.service'

import ReactGA from 'react-ga'

import {
  defaultState,
  LOCAL_STORAGE_REMEMBER,
  LOCAL_STORAGE_EMAIL,
  FIELD,
} from './config'
import { getChanges, getPostData, validateForm, errorHandler } from './logic'
import { downloadFile } from './service'

const FileDownload = require('js-file-download')

function Form() {
  const env = process?.env?.REACT_APP_HOST_ENV
  const [loading, toggleLoading] = useState(false)
  const [showPopup, togglePopup] = useState(null)
  const [errors, setErrors] = useState(defaultState.errors)
  const [rememberMe, setRemember] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_REMEMBER) || false)
  )
  const [state, setState] = useState({
    ...defaultState,
    submitted: false,
    email: localStorage.getItem(LOCAL_STORAGE_EMAIL) || '',
  })

  popupService.popup$.subscribe(content => {
    if (content) {
      togglePopup(content)
    } else {
      togglePopup(null)
    }
  })

  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_SITE_ID, 'n/a')
  }, [])

  useEffect(() => {
    // keep the email in sync with local storage
    localStorage.setItem(LOCAL_STORAGE_REMEMBER, rememberMe)
    if (rememberMe && !errors['email']) {
      localStorage.setItem(LOCAL_STORAGE_EMAIL, state.email)
    } else {
      localStorage.removeItem(LOCAL_STORAGE_EMAIL)
    }
  }, [state.email, errors, rememberMe])

  useEffect(() => {
    // validate the form after every state change
    setErrors(validateForm(state, state.submitted))
  }, [state])

  const handleDownload = async () => {
    const errors = validateForm(state, true)
    const canSubmit = !hasFormErrors(errors)

    console.log(errors)

    if (canSubmit) {
      ReactGA.event({
        category: 'User',
        action: 'Download Photometry',
      })
      const data = getPostData(state)

      toggleLoading(true)

      let photometry
      try {
        photometry = await downloadFile(data)
      } catch (e) {
        window.scrollTo(0, 0)
        popupService.showErrorPopUp()
        toggleLoading(false)
        console.error(errorHandler(e))
      }
      toggleLoading(false)
      if (photometry) {
        const fileName = photometry.headers['content-disposition']
          .match(/(?:"[^"]*"|^[^"]*$)/)[0]
          .replace(/"/g, '')

        FileDownload(photometry.data, fileName)
      }
    } else {
      setState({
        ...state,
        submitted: true,
      })
      setErrors(errors)
    }
  }

  const onChangeHandler = field => {
    return value => {
      return updateField(field, value)
    }
  }

  const updateField = (field, value) => {
    const newState = getChanges(state, field, value)
    setState({
      ...newState,
    })
  }

  const hasFormErrors = errors => Object.keys(errors).length > 0
  return (
    <>
      {loading && <Loader msg={'Loading...'} />}
      {showPopup && (
        <PopUp onClose={() => togglePopup(null)}>{showPopup}</PopUp>
      )}
      <ErrorContext.Provider value={errors}>
        <DistributionType
          data={state}
          onChange={onChangeHandler(FIELD.distribution)}
        />
        <DownlightSpecification
          data={state}
          downlightTypeChanged={onChangeHandler(FIELD.downDistribution)}
          downlightChanged={onChangeHandler(FIELD.downlight)}
          downlightLumensChanged={onChangeHandler(FIELD.downLumens)}
          inputModeChanged={onChangeHandler(FIELD.downInputMode)}
        />
        <UplightSpecification
          data={state}
          uplightTypeChanged={onChangeHandler(FIELD.upDistribution)}
          uplightChanged={onChangeHandler(FIELD.uplight)}
          uplightLumensChanged={onChangeHandler(FIELD.upLumens)}
          inputModeChanged={onChangeHandler(FIELD.upInputMode)}
        />
        <File
          data={state}
          fileLengthChanged={onChangeHandler(FIELD.fixtureLength)}
          fileTypeChanged={onChangeHandler(FIELD.fileType)}
          profileChanged={onChangeHandler(FIELD.fixtureWidth)}
        />
        <Details
          data={state}
          emailChanged={onChangeHandler(FIELD.email)}
          rememberMe={rememberMe}
          rememberMeChanged={remember => setRemember(remember)}
        />
        <Download
          text={
            hasFormErrors(errors) && state.submitted
              ? 'Fix form errors'
              : 'Download'
          }
          disabled={hasFormErrors(errors)}
          onDownload={handleDownload}
        />
        {env === 'development' && <Debug data={state}></Debug>}
      </ErrorContext.Provider>
    </>
  )
}

export default Form
