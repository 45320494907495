export const LOCAL_STORAGE_REMEMBER = 'energyline.remember'
export const LOCAL_STORAGE_EMAIL = 'energyline.email'

export const FIELD = {
  // Type
  distribution: 'distribution',
  // Downlight
  downDistribution: 'downDistribution',
  downlight: 'downlight',
  downLumens: 'downLumens',
  // Uplight
  upDistribution: 'upDistribution',
  uplight: 'uplight',
  upLumens: 'upLumens',
  // File
  fileType: 'fileType',
  fixtureLength: 'fixtureLength',
  fixtureWidth: 'fixtureWidth',
  // UI
  downInputMode: 'downInputMode',
  upInputMode: 'upInputMode',
  // Misc
  email: 'email',
  errors: 'errors',
}

export const DISTRIBUTION = {
  UP_DOWN: 'up_down',
  UP: 'up',
  DOWN: 'down',
}
export const LIGHT_TYPE = {
  CEILING_WASH: 'ceiling wash',
  DOUBLE_WASH: 'double wash',
  LOUVRE: 'louvre',
  MICROPRISM: 'micro prism',
  SATIN: 'satin',
  WALL_WASH: 'wall wash',
}
export const FILE = {
  IES: 'ies',
  LDT: 'ldt',
}
export const INPUT_MODE = {
  SLIDER: 'slider',
  RADIO: 'radio',
  TEXT: 'textcontrol',
}

export const defaultState = {
  // Type
  [FIELD.distribution]: DISTRIBUTION.UP_DOWN,
  // Downlight
  [FIELD.downDistribution]: LIGHT_TYPE.SATIN,
  [FIELD.downlight]: 2000,
  [FIELD.downLumens]: 2000,
  // Uplight
  [FIELD.upDistribution]: LIGHT_TYPE.SATIN,
  [FIELD.uplight]: 0.3,
  [FIELD.upLumens]: 600,
  // File
  [FIELD.fixtureLength]: 1000,
  [FIELD.fixtureWidth]: 75,
  [FIELD.fileType]: FILE.IES,
  // UI
  [FIELD.downInputMode]: INPUT_MODE.RADIO,
  [FIELD.upInputMode]: INPUT_MODE.RADIO,
  // Misc
  [FIELD.email]: '',
  [FIELD.errors]: {},
}

export const distributionTypes = [
  { url: '../images/down.png', id: DISTRIBUTION.DOWN },
  { url: '../images/up-down.png', id: DISTRIBUTION.UP_DOWN },
  { url: '../images/up.png', id: DISTRIBUTION.UP },
]

export const downlightTypes = [
  {
    url: '../images/satin.svg',
    id: LIGHT_TYPE.SATIN,
    label: 'Satin Diffuser',
  },
  {
    url: '../images/65-microprism.png',
    id: LIGHT_TYPE.MICROPRISM,
    label: 'Microprism Diffuser',
  },
  {
    url: '../images/louvre.svg',
    id: LIGHT_TYPE.LOUVRE,
    label: 'Louvre',
  },
  {
    url: '../images/wall-wash.svg',
    id: LIGHT_TYPE.WALL_WASH,
    label: 'Wall Wash',
  },
  {
    url: '../images/double-wash.svg',
    id: LIGHT_TYPE.DOUBLE_WASH,
    label: 'Double Wash',
  },
]

export const lumensOutputs = [
  {
    id: 1000,
    btnLabel: 1000,
  },
  {
    id: 1500,
    btnLabel: 1500,
  },
  {
    id: 2000,
    btnLabel: 2000,
  },
  {
    id: 2500,
    btnLabel: 2500,
  },
  {
    id: 3000,
    btnLabel: 3000,
  },
]

export const fileTypes = [
  {
    id: FILE.IES,
    btnLabel: 'IES',
    label: 'Optimised For AGI',
  },
  {
    id: FILE.LDT,
    btnLabel: 'LDT',
    label: 'Optimised For Dialux',
  },
]

export const profileWidths = [
  {
    id: 55,
    btnLabel: 55,
    disabled: false,
  },
  {
    id: 65,
    btnLabel: 65,
    disabled: false,
  },
  {
    id: 75,
    btnLabel: 75,
    disabled: false,
  },
  {
    id: 108,
    btnLabel: 108,
    disabled: false,
  },
]

export const uplightTypes = [
  {
    url: '../images/satin-up.png',
    id: LIGHT_TYPE.SATIN,
    label: 'Satin Diffuser',
  },
  {
    url: '../images/ceiling-wash.png',
    id: LIGHT_TYPE.CEILING_WASH,
    label: 'Ceiling Wash',
  },
]

export const uplightOptions = [
  {
    id: 0.3,
    btnLabel: '30%',
  },
  {
    id: 0.5,
    btnLabel: '50%',
  },
  {
    id: 1,
    btnLabel: '100%',
  },
]

export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/ // eslint-disable-line
