/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import debounce from 'lodash/debounce'

export const useVisibleOnScroll = breakpoint => {
  const [visible, setVisible] = useState(true)
  const [lastPositionY, setLastPositionY] = useState(true)
  const [positionY, setPositionY] = useState(0)
  const onPositionChange = (posY, lastY) => {
    if (posY <= breakpoint) return setVisible(true)
    if (posY > lastY) setVisible(false)
    if (posY < lastY) setVisible(true)
    setLastPositionY(posY)
  }
  const observePositionY = () => setPositionY(window.pageYOffset)

  useEffect(() => {
    window.addEventListener('scroll', debounce(observePositionY, 10))
  }, [])

  useEffect(() => {
    onPositionChange(positionY, lastPositionY)
  }, [positionY, breakpoint])

  return visible
}

export const useIntersectionObserver = (
  elementRef,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }
) => {
  const [entry, setEntry] = useState()

  const frozen = entry?.isIntersecting && freezeOnceVisible

  const updateEntry = ([entry]) => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = elementRef && elementRef.current // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen])

  return entry
}
