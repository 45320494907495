import styled from 'styled-components'

const Label = styled.div`
  font-family: 'SinkinSans', sans-serif;
`

const InputLabel = styled(Label)`
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 1em;
`

const ButtonLabel = styled(Label)`
  font-family: 'SinkinSansLight', sans-serif;
  font-size: 0.75rem;
`

export default Label

export { InputLabel, ButtonLabel }
