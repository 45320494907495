import styled from 'styled-components'

import defaultTheme from '../styles/theme'

const { CONTAINER_MAX_WIDTH } = defaultTheme

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ gap }) => gap && `margin-bottom: var(--box-space);`};
  @media (min-width: ${CONTAINER_MAX_WIDTH}) {
    flex-direction: row;
  }
  & > * {
    margin-right: var(--box-space);
  }
  & > *:last-child {
    margin-right: 0;
  }
`

export const Col = styled.div`
  border: 0px dashed red;
  width: 100%;
  ${({ verticalCenter }) =>
    verticalCenter &&
    `
    display: flex;
    align-items: center;
  `}
  @media (min-width: ${CONTAINER_MAX_WIDTH}) {
    width ${({ width }) => (width ? width : '100%')};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`}
  }
`

export const Title = styled.div`
  font-family: var(--font-sans_serif__bold);
  font-size: 0.75rem;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 1rem;
`
