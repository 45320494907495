import React from 'react'
import styled from 'styled-components'

const Text = styled.p`
  font-size: 0.8em;
  margin-top: 1em;
`

const Title = styled.h3`
  font-size: 1em;
`

const Img = styled.img`
  max-width: 100%;
`

const ErrorContent = (
  <>
    <Title>Error</Title>
    <Text>Sorry, something went wrong, please try again later</Text>
  </>
)

const HelpContent = <Img src={'../images/photometry-file-naming.png'} />

export { ErrorContent, HelpContent }
