import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

import Form from './containers/Form'
import { Provider as OptionsProvider } from './context/OptionsContext'
import Header from './UI/Header'
import { useIntersectionObserver } from './components/hooks'

import cssReset from './styles/cssReset'
import SinkinSansBold from './fonts/SinkinSans-700Bold-webfont.woff'
import SinkinSansLight from './fonts/SinkinSans-200XLight-webfont.woff'
import SinkinSansRegular from './fonts/SinkinSans-400Regular-webfont.woff'

import Footer from './components/Footer'
import theme from './styles/theme'

import ReactGA from 'react-ga'

import './styles/tooltips.css'
import './styles/variables.css'

const GlobalStyle = createGlobalStyle`
  ${cssReset}

  @font-face {
    font-family: 'SinkinSansRegular';
    src: url(${SinkinSansRegular}) format('woff2');
    font-weight: 400;
  font-style: normal;
  font-display: swap;
  }

  @font-face {
    font-family: 'SinkinSansBold';
    src: url(${SinkinSansBold}) format('woff2');
    font-weight: 700;
  font-style: normal;
  font-display: swap;
  }

  @font-face {
    font-family: 'SinkinSansLight';
    src: url(${SinkinSansLight}) format('woff2');
    font-weight: 200;
  font-style: normal;
  font-display: swap;
  }

  body {
    font-family: var(--font-sans_serif);
    color: ${theme.TEXT_COLOR};
  }
  a {
    color: steelblue;
  }

  a:hover {
    text-decoration-color: steelblue;
  }
`

const AppContainer = styled.div`
  background-color: ${theme.BACKGROUND};
  width: 100%;
  margin: 0 auto;
  position: relative;
`

const AppBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  width: ${theme.CONTAINER_WIDTH};
  max-width: ${theme.CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  position: relative;
  z-index: 0;
  @media (max-width: ${theme.CONTAINER_MAX_WIDTH}) {
    width: 96%;
    max-width: 96%;
  }
`

// Add google analytics tracking
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)

const App = () => {
  const [collapsed, setCollapsed] = React.useState(false)

  const ref = React.useRef(null)
  const entry = useIntersectionObserver(ref, {
    threshold: 0.7,
  })
  const isIntersecting = entry && entry.isIntersecting

  React.useEffect(() => {
    setCollapsed(!isIntersecting)
  }, [isIntersecting])

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <OptionsProvider>
      <ThemeProvider theme={theme}>
        <AppContainer>
          <Header collapsed={collapsed} />
          <div className="h-24 lg:h-44 " ref={ref}></div>
          <AppBody>
            <Form />
          </AppBody>
          <Footer />
          <GlobalStyle />
        </AppContainer>
      </ThemeProvider>
    </OptionsProvider>
  )
}

export default App
