import React from 'react'
import styled from 'styled-components'

import { getPostData } from '../containers/logic'

const Container = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  right: 2%;
  bottom: 2%;
  color: white;
  font-size: 0.75rem;
  font-family: 'Courier New', system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
    Cantarell, Noto Sans, sans-serif;
  padding: 1rem 1.25rem;
  border-radius: 3px;
  z-index: 10;
  width: 340px;
  overflow: hidden;
  box-shadow: var(--shadow);
`

const Th = styled.td`
  font-weight: bold;
  text-align: left;
  padding-right: 0.5rem;
`

const Debug = ({ data }) => {
  const postData = getPostData(data)
  const {
    source,
    distribution,
    downDistribution,
    downLumens,
    email,
    fileType,
    fixtureLength,
    fixtureWidth,
    upDistribution,
    upLumens,
  } = postData
  const defaultValue = '----------------'
  return (
    <Container>
      <pre>
        <table>
          <tbody>
            <tr>
              <Th>App Name:</Th>
              <td>{source || defaultValue}</td>
            </tr>
            <tr>
              <Th>Type:</Th>
              <td>{distribution || defaultValue}</td>
            </tr>
            <tr>
              <Th>Distribution (Down):</Th>
              <td>{downDistribution || defaultValue}</td>
            </tr>
            <tr>
              <Th>Lumens (Down):</Th>
              <td>{downLumens || defaultValue}</td>
            </tr>
            <tr>
              <Th>Distribution (Up):</Th>
              <td>{upDistribution || defaultValue}</td>
            </tr>
            <tr>
              <Th>Lumens (Up):</Th>
              <td>{upLumens || defaultValue}</td>
            </tr>
            <tr>
              <Th>Fixture Length:</Th>
              <td>{fixtureLength || defaultValue}</td>
            </tr>
            <tr>
              <Th>Fixture Width:</Th>
              <td>{fixtureWidth || defaultValue}</td>
            </tr>
            <tr>
              <Th>File Type:</Th>
              <td>{fileType || defaultValue}</td>
            </tr>
            <tr>
              <Th>Email:</Th>
              <td>{email || defaultValue}</td>
            </tr>
          </tbody>
        </table>
      </pre>
    </Container>
  )
}

export default Debug
