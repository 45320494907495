import React from 'react'
import { cx } from '@emotion/css'

export const routes = [
  { pathname: 'https://www.energyline.net/', label: 'Home' },
  { pathname: 'https://www.energyline.net/products/', label: 'Products' },
  { pathname: 'https://projects.energyline.net/', label: 'Projects' },
  { pathname: 'https://www.energyline.net/innovation/', label: 'Innovation' },
  { pathname: 'https://www.energyline.net/who-we-are/', label: 'Who We Are' },
  { pathname: 'https://www.energyline.net/contact/', label: 'Contact' },
]

const Header = ({ collapsed = false, titleCategory = '' }) => {
  return (
    <section
      className={cx(
        'hidden',
        'lg:flex space-x-4 items-center justify-between',
        'bg-white lg:px-4',
        'transition-all ease-in-out duration-100',
        {
          'h-0': collapsed,
          'h-20': !collapsed,
        }
      )}
    >
      <div className="w-full max-w-7xl mx-auto">
        <div className="relative w-[650px] hidden sm:block">
          <div
            className={cx(
              'absolute left-0 transition-all duration-100 ease-in-out',
              {
                '-top-[35px]': collapsed,
                'top-[-2px]': !collapsed,
              }
            )}
          >
            <div className="w-52 h-auto">
              <LogoEL />
            </div>
          </div>
        </div>

        <nav
          className={cx(
            'w-full flex flex-col items-end transition-all ease-in-out duration-100',
            { invisible: collapsed }
          )}
        >
          <ul className="flex space-x-4 text-sm justify-between">
            {routes.map(({ pathname, label }) => (
              <li className="text-center" key={label}>
                <a
                  href={pathname}
                  className="text-black no-underline hover:text-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </section>
  )
}

export default Header

const LogoEL = props => (
  <svg viewBox="0 0 262 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M3.99505 28.7382V17.8717H19.2917V14.4866H3.99505V4.63683H20.5056V1.37512H3.99505H2.0918H0V31.9999H0.730659H3.99505H20.865V28.7382H3.99505Z"
        fill="#000"
      />
      <path
        d="M47.9289 1.37512V26.217L31.7012 1.37512H31.0884H27.2465V31.9999H31.0884V7.09927L47.4457 31.9999H47.9289H48.577H51.7707V1.37512H47.9289Z"
        fill="#000"
      />
      <path
        d="M63.6026 28.7382V17.8717H78.8934V14.4866H63.6026V4.63683H80.1073V1.37512H63.6026H61.6935H59.6017V31.9999H60.3323H63.6026H80.4667V28.7382H63.6026Z"
        fill="#000"
      />
      <path
        d="M106.44 31.994H110.983L102.528 19.3939C106.653 19.0354 109.917 15.1448 109.917 10.3845C109.917 5.3832 106.328 1.33398 101.909 1.33398C101.762 1.33398 101.626 1.33398 101.485 1.33398H86.9837V31.947H91.1084V19.676H98.303L106.44 31.994ZM91.1201 4.61332H101.497C102.812 4.96326 103.975 5.73875 104.801 6.81826C105.628 7.89776 106.071 9.22025 106.063 10.5784C106.063 13.3759 104.39 16.0851 100.454 16.2673H91.1437L91.1201 4.61332Z"
        fill="#000"
      />
      <path
        d="M139.65 8.49804L142.184 5.81227C142.184 5.81227 137.823 -0.27625 128.602 -3.3374e-05C119.38 0.276183 115.226 8.48629 114.961 12.9704C114.696 17.4545 114.266 30.4661 127.105 31.9706C132.544 31.9706 136.327 31.4182 138.89 27.51C138.89 29.3612 138.89 29.8901 138.89 31.3241L142.207 31.2771V15.28H128.655V18.5182H138.501C138.501 18.5182 139.962 28.5091 128.543 28.4562C124.194 28.5326 115.886 23.5783 120.04 9.55001C125.933 -1.29884 137.234 4.61925 139.65 8.49804Z"
        fill="#000"
      />
      <path
        d="M169.972 1.37512L165.777 1.40451L157.209 15.3094L148.642 1.40451L144.441 1.37512L155.1 18.6651V31.9999H159.107V19.0001L169.972 1.37512Z"
        fill="#000"
      />
      <path
        d="M177.615 28.6384V1.35168H173.62V32H177.426H177.615H192.528V28.6384H177.615Z"
        fill="#F59B2C"
      />
      <path
        d="M202.481 31.9999V1.37512L198.492 1.37512V31.9999H202.481Z"
        fill="#F59B2C"
      />
      <path
        d="M230.652 1.33398V26.3051L214.49 1.37512H214.048H213.411H210.053V31.9999H214.048V6.89358L230.199 31.9647H230.652H230.959H234.642V1.33398H230.652Z"
        fill="#F59B2C"
      />
      <path d="M262 0.940308H241.382V4.19614H262V0.940308Z" fill="#F59B2C" />
      <path d="M262 14.6277H241.382V17.8835H262V14.6277Z" fill="#F59B2C" />
      <path d="M262 28.3269H241.382V31.5827H262V28.3269Z" fill="#F59B2C" />
    </g>
  </svg>
)
