/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import Box from '../UI/Box'
import { Row, Title, Col } from '../UI/Layout'
import RadioButton from '../UI/RadioButton'
import RadioImageButton from '../UI/RadioImageButton'

import { FIELD, DISTRIBUTION, INPUT_MODE } from '../containers/config'

import TextControl from './TextControl'

import { Context as OptionsContext } from '../context/OptionsContext'

const Note = styled.p`
  font-size: 0.725em;
  margin-top: 0.25em;
  &:first-of-type {
    margin-top: 1em;
  }
`

const totalLumens = ({ upLumens, downLumens }) => {
  const up = Number(upLumens)
  const down = Number(downLumens)
  if (Number.isNaN(up) || Number.isNaN(down)) return 0
  return up + down
}

const UplightSpecification = ({
  data,
  uplightLumensChanged,
  uplightChanged,
  uplightTypeChanged,
  inputModeChanged,
}) => {
  const {
    state: options,
    updateProfileWidths,
    updateDistributionType,
  } = useContext(OptionsContext)

  const [mode, setMode] = useState(INPUT_MODE.RADIO)

  useEffect(() => {
    updateProfileWidths({
      distribution: data.distribution,
      downDistribution: data.downDistribution,
      upDistribution: data.upDistribution,
    })
    updateDistributionType({
      profileWidths: data.fixtureWidth,
    })
  }, [data])

  useEffect(() => {
    inputModeChanged(mode)
  }, [mode])

  const isDisDown = data.distribution === DISTRIBUTION.DOWN
  const isDisUp = data.distribution === DISTRIBUTION.UP
  const isDisUpDown = data.distribution === DISTRIBUTION.UP_DOWN

  const lightOutputLabel = isDisUp
    ? 'Light output (Lumens per metre)'
    : 'Light output (% of Downlight lumens/m)'

  return (
    <Box collapsed={isDisDown} title={'Indirect Lighting Distribution'}>
      {/* Row 1 */}
      <Row gap>
        <Col>
          <Title>Distribution</Title>
          <RadioImageButton
            options={options.uplightTypes}
            onOptionClick={uplightTypeChanged}
            selected={data.upDistribution}
          />
        </Col>
      </Row>
      {/* Row 2 */}
      <Row>
        <Col maxWidth="var(--radio-group-max_width)">
          <Title>{lightOutputLabel}</Title>
          <RadioButton
            options={isDisUp ? options.lumensOutputs : options.uplightOptions}
            disabled={mode === INPUT_MODE.TEXT}
            onEnable={() => setMode(INPUT_MODE.RADIO)}
            onOptionClick={uplightChanged}
            selected={data.uplight}
          />
          <TextControl
            disabled={mode === INPUT_MODE.RADIO}
            onEnable={() => setMode(INPUT_MODE.TEXT)}
            errorKey={FIELD.upLumens}
            label="Or enter custom lumens/m"
            onChange={uplightLumensChanged}
            value={data.upLumens}
          />
          {data.upInputMode === 'radio' ? (
            <Note>Selected output is equivalent to {data.upLumens} lm/m</Note>
          ) : null}
          {isDisUpDown && (
            <Note>Total luminaire output: {totalLumens(data)} lm/m</Note>
          )}
        </Col>
      </Row>
    </Box>
  )
}

export default UplightSpecification
