import createDataContext from './createDataContext'
import {
  profileWidths,
  distributionTypes,
  fileTypes,
  uplightTypes,
  uplightOptions,
  lumensOutputs,
  downlightTypes,
} from '../containers/config'
import {
  disableLouvre,
  shouldDisableLouvre,
  enable55,
  shouldEnable55,
  disableMicroprism,
  shouldDisableMicroprism,
  enable65,
  shouldEnable65,
  shouldDisableSatinUp,
  disableSatinUp,
  enable108,
  shouldEnable108,
  disableCeilingWash,
  shouldDisableCeilingWash,
} from '../containers/logic'

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_distribution_type':
      // * Business Logic:
      const isLouvreDisabled = shouldDisableLouvre(action.payload.profileWidths)
      state.downlightTypes = disableLouvre(
        state.downlightTypes,
        isLouvreDisabled
      )
      state.uplightTypes = disableLouvre(state.uplightTypes, isLouvreDisabled)

      const isMicroprismDisabled = shouldDisableMicroprism(
        action.payload.profileWidths
      )
      state.downlightTypes = disableMicroprism(
        state.downlightTypes,
        isMicroprismDisabled
      )

      const isSatinUpDisabled = shouldDisableSatinUp(
        action.payload.profileWidths
      )
      state.uplightTypes = disableSatinUp(state.uplightTypes, isSatinUpDisabled)

      const isCeilingWashDisabled = shouldDisableCeilingWash(
        action.payload.profileWidths
      )
      state.uplightTypes = disableCeilingWash(
        state.uplightTypes,
        isCeilingWashDisabled
      )

      return state
    case 'update_profileWidths':
      // * Business Logic:
      // Update profileWidths when uplight distribution change
      state.profileWidths = enable55(
        state.profileWidths,
        shouldEnable55(
          action.payload.distribution,
          action.payload.downDistribution,
          action.payload.upDistribution
        )
      )
      state.profileWidths = enable65(
        state.profileWidths,
        shouldEnable65(
          action.payload.distribution,
          action.payload.downDistribution,
          action.payload.upDistribution
        )
      )
      state.profileWidths = enable108(
        state.profileWidths,
        shouldEnable108(
          action.payload.distribution,
          action.payload.downDistribution
        )
      )
      return state
    default:
      return state
  }
}

const updateDistributionType = dispatch => ({ profileWidths }) => {
  dispatch({
    type: 'update_distribution_type',
    payload: { profileWidths },
  })
}

const updateProfileWidths = dispatch => ({
  distribution,
  downDistribution,
  upDistribution,
}) => {
  dispatch({
    type: 'update_profileWidths',
    payload: { distribution, downDistribution, upDistribution },
  })
}

const defaultState = {
  profileWidths,
  distributionTypes,
  fileTypes,
  uplightTypes,
  uplightOptions,
  lumensOutputs,
  downlightTypes,
}

export const { Context, Provider } = createDataContext(
  reducer,
  { updateProfileWidths, updateDistributionType },
  defaultState
)
